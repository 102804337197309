/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getInfo as t } from "../../../geometry/support/spatialReferenceUtils.js";
import r from "./TileKey.js";
function i(t, r) {
  return [t, r];
}
function o(t, r, i) {
  return t[0] = r, t[1] = i, t;
}
function e(t, r, i, o, e) {
  return t[0] = r, t[1] = i, t[2] = o, t[3] = e, t;
}
const s = new r("0/0/0/0");
class l {
  static create(r, e, s = null) {
    const n = t(r.spatialReference),
      a = e.origin || i(r.origin.x, r.origin.y),
      h = i(r.size[0] * e.resolution, r.size[1] * e.resolution),
      u = i(-1 / 0, -1 / 0),
      c = i(1 / 0, 1 / 0),
      m = i(1 / 0, 1 / 0);
    null != s && (o(u, Math.max(0, Math.floor((s.xmin - a[0]) / h[0])), Math.max(0, Math.floor((a[1] - s.ymax) / h[1]))), o(c, Math.max(0, Math.floor((s.xmax - a[0]) / h[0])), Math.max(0, Math.floor((a[1] - s.ymin) / h[1]))), o(m, c[0] - u[0] + 1, c[1] - u[1] + 1));
    const {
      cols: g,
      rows: w
    } = e;
    let d, f, p, z;
    return !s && g && w && (o(u, g[0], w[0]), o(c, g[1], w[1]), o(m, g[1] - g[0] + 1, w[1] - w[0] + 1)), r.isWrappable ? (d = i(Math.ceil(Math.round((n.valid[1] - n.valid[0]) / e.resolution) / r.size[0]), m[1]), f = !0, p = n.origin, z = n.valid) : (d = m, f = !1), new l(e.level, e.resolution, e.scale, a, u, c, m, h, d, f, p, z);
  }
  constructor(t, r, i, o, e, s, l, n, a, h, u, c) {
    this.level = t, this.resolution = r, this.scale = i, this.origin = o, this.first = e, this.last = s, this.size = l, this.norm = n, this.worldSize = a, this.wrap = h, this._spatialReferenceOrigin = u, this._spatialReferenceValid = c;
  }
  normalizeCol(t) {
    if (!this.wrap) return t;
    const r = this.worldSize[0];
    return t < 0 ? r - 1 - Math.abs((t + 1) % r) : t % r;
  }
  normalizeKey(t) {
    if (!this.wrap) return;
    const r = this.worldSize[0],
      i = t.col;
    i < 0 ? (t.col = i + r, t.world -= 1) : i >= r && (t.col = i - r, t.world += 1);
  }
  denormalizeCol(t, r) {
    return this.wrap ? this.worldSize[0] * r + t : t;
  }
  getWorldForColumn(t) {
    return this.wrap ? Math.floor(t / this.worldSize[0]) : 0;
  }
  getFirstColumnForWorld(t) {
    return t * this.worldSize[0] + this.first[0];
  }
  getLastColumnForWorld(t) {
    return t * this.worldSize[0] + this.first[0] + this.size[0] - 1;
  }
  getColumnForX(t) {
    return (t - this.origin[0]) / this.norm[0];
  }
  getXForColumn(t) {
    const r = this.origin[0] + t * this.norm[0],
      i = this._spatialReferenceOrigin,
      o = this._spatialReferenceValid;
    return this.wrap && i && o ? r === i[0] ? o[0] : this.origin[0] === i[0] && t === this.worldSize[0] ? o[1] : r : r;
  }
  getRowForY(t) {
    return (this.origin[1] - t) / this.norm[1];
  }
  getYForRow(t) {
    return this.origin[1] - t * this.norm[1];
  }
  getTileBounds(t, r, i = !1) {
    s.set(r);
    const o = i ? s.col : this.denormalizeCol(s.col, s.world),
      l = s.row;
    return e(t, this.getXForColumn(o), this.getYForRow(l + 1), this.getXForColumn(o + 1), this.getYForRow(l)), t;
  }
  getTileCoords(t, r, i = !1) {
    s.set(r);
    const e = i ? s.col : this.denormalizeCol(s.col, s.world);
    return Array.isArray(t) ? o(t, this.getXForColumn(e), this.getYForRow(s.row)) : (t.x = this.getXForColumn(e), t.y = this.getYForRow(s.row)), t;
  }
}
export { l as default };