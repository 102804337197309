/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import o from "../../../core/ObjectPool.js";
import l from "./TileKey.js";
class s {
  constructor() {
    this.spans = [];
  }
  acquire(o) {
    this.lodInfo = o;
  }
  release() {
    this.lodInfo = null, this.spans.length = 0;
  }
  *keys() {
    const o = this.lodInfo;
    for (const {
      row: s,
      colFrom: e,
      colTo: r
    } of this.spans) for (let t = e; t <= r; t++) {
      const e = o.getWorldForColumn(t);
      yield new l(o.level, s, o.normalizeCol(t), e);
    }
  }
  forEach(o, l) {
    const {
        spans: s,
        lodInfo: e
      } = this,
      {
        level: r
      } = e;
    if (0 !== s.length) for (const {
      row: t,
      colFrom: n,
      colTo: c
    } of s) for (let s = n; s <= c; s++) o.call(l, r, t, e.normalizeCol(s), e.getWorldForColumn(s));
  }
}
s.pool = new o(s);
export { s as default };