/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getInfo as e } from "../../../geometry/support/spatialReferenceUtils.js";
import t from "./LODInfo.js";
import o from "./TileCoverage.js";
import l from "./TileKey.js";
import s from "./TileSpan.js";
const i = new l("0/0/0/0");
class n {
  static create(e, t) {
    e[1] > t[1] && ([e, t] = [t, e]);
    const [o, l] = e,
      [s, i] = t,
      r = s - o,
      a = i - l,
      h = 0 !== a ? r / a : 0,
      c = (Math.ceil(l) - l) * h,
      f = (Math.floor(l) - l) * h;
    return new n(o, Math.floor(l), Math.ceil(i), h, r < 0 ? c : f, r < 0 ? f : c, r < 0 ? s : o, r < 0 ? o : s);
  }
  constructor(e, t, o, l, s, i, n, r) {
    this.x = e, this.ymin = t, this.ymax = o, this.invM = l, this.leftAdjust = s, this.rightAdjust = i, this.leftBound = n, this.rightBound = r;
  }
  incrRow() {
    this.x += this.invM;
  }
  getLeftCol() {
    return Math.max(this.x + this.leftAdjust, this.leftBound);
  }
  getRightCol() {
    return Math.min(this.x + this.rightAdjust, this.rightBound);
  }
}
const r = [[0, 0], [0, 0], [0, 0], [0, 0]],
  a = 1e-6;
class h {
  constructor(e, o = null, l = e.lods[0].level, s = e.lods[e.lods.length - 1].level) {
    this.tileInfo = e, this.fullExtent = o, this.scales = [], this._infoByScale = {}, this._infoByLevel = {};
    const i = e.lods.filter(e => e.level >= l && e.level <= s);
    this.minScale = i[0].scale, this.maxScale = i[i.length - 1].scale;
    const n = this._lodInfos = i.map(l => t.create(e, l, o));
    i.forEach((e, t) => {
      this._infoByLevel[e.level] = n[t], this._infoByScale[e.scale] = n[t], this.scales[t] = e.scale;
    }, this), this._wrap = e.isWrappable;
  }
  get spatialReference() {
    return this.tileInfo.spatialReference;
  }
  getLODInfoAt(e) {
    return this._infoByLevel["number" == typeof e ? e : e.level];
  }
  getTileBounds(e, t, o = !1) {
    i.set(t);
    const l = this._infoByLevel[i.level];
    return l ? l.getTileBounds(e, i, o) : e;
  }
  getTileCoords(e, t, o = !1) {
    i.set(t);
    const l = this._infoByLevel[i.level];
    return l ? l.getTileCoords(e, i, o) : e;
  }
  getTileCoverage(e, t = 192, l = !0, i = "closest") {
    if (!l && (e.scale > this.minScale || e.scale < this.maxScale)) return null;
    const a = "closest" === i ? this.getClosestInfoForScale(e.scale) : this.getSmallestInfoForScale(e.scale),
      h = o.pool.acquire(a),
      c = this._wrap;
    let f,
      u,
      m,
      g = 1 / 0,
      d = -1 / 0;
    const v = h.spans;
    r[0][0] = r[0][1] = r[1][1] = r[3][0] = -t, r[1][0] = r[2][0] = e.size[0] + t, r[2][1] = r[3][1] = e.size[1] + t;
    for (const o of r) e.toMap(o, o), o[0] = a.getColumnForX(o[0]), o[1] = a.getRowForY(o[1]);
    const y = [];
    let _ = 3;
    for (let o = 0; o < 4; o++) {
      if (r[o][1] === r[_][1]) {
        _ = o;
        continue;
      }
      const e = n.create(r[o], r[_]);
      g = Math.min(e.ymin, g), d = Math.max(e.ymax, d), void 0 === y[e.ymin] && (y[e.ymin] = []), y[e.ymin].push(e), _ = o;
    }
    if (null == g || null == d || d - g > 100) return null;
    let p = [];
    for (f = g; f < d;) {
      null != y[f] && (p = p.concat(y[f])), u = 1 / 0, m = -1 / 0;
      for (let e = p.length - 1; e >= 0; e--) {
        const t = p[e];
        u = Math.min(u, t.getLeftCol()), m = Math.max(m, t.getRightCol());
      }
      if (u = Math.floor(u), m = Math.floor(m), f >= a.first[1] && f <= a.last[1]) if (c) {
        if (a.size[0] < a.worldSize[0]) {
          const e = Math.floor(m / a.worldSize[0]);
          for (let t = Math.floor(u / a.worldSize[0]); t <= e; t++) v.push(new s(f, Math.max(a.getFirstColumnForWorld(t), u), Math.min(a.getLastColumnForWorld(t), m)));
        } else v.push(new s(f, u, m));
      } else u > a.last[0] || m < a.first[0] || (u = Math.max(u, a.first[0]), m = Math.min(m, a.last[0]), v.push(new s(f, u, m)));
      f += 1;
      for (let e = p.length - 1; e >= 0; e--) {
        const t = p[e];
        t.ymax >= f ? t.incrRow() : p.splice(e, 1);
      }
    }
    return h;
  }
  getTileParentId(e) {
    i.set(e);
    const t = this._infoByLevel[i.level],
      o = this._lodInfos.indexOf(t) - 1;
    return o < 0 ? null : (this._getTileIdAtLOD(i, this._lodInfos[o], i), i.id);
  }
  getTileResolution(e) {
    const t = this._infoByLevel["object" == typeof e ? e.level : e];
    return t ? t.resolution : -1;
  }
  getTileScale(e) {
    const t = this._infoByLevel[e.level];
    return t ? t.scale : -1;
  }
  intersects(e, t) {
    i.set(t);
    const o = this._infoByLevel[i.level],
      l = e.lodInfo;
    if (l.resolution > o.resolution) {
      this._getTileIdAtLOD(i, l, i);
      const t = l.denormalizeCol(i.col, i.world);
      for (const o of e.spans) if (o.row === i.row && o.colFrom <= t && o.colTo >= t) return !0;
    }
    if (l.resolution < o.resolution) {
      const [t, s, n, r] = e.spans.reduce((e, t) => (e[0] = Math.min(e[0], t.row), e[1] = Math.max(e[1], t.row), e[2] = Math.min(e[2], t.colFrom), e[3] = Math.max(e[3], t.colTo), e), [1 / 0, -1 / 0, 1 / 0, -1 / 0]),
        a = o.denormalizeCol(i.col, i.world),
        h = l.getColumnForX(o.getXForColumn(a)),
        c = l.getRowForY(o.getYForRow(i.row)),
        f = l.getColumnForX(o.getXForColumn(a + 1)) - 1,
        u = l.getRowForY(o.getYForRow(i.row + 1)) - 1;
      return !(h > r || f < n || c > s || u < t);
    }
    const s = l.denormalizeCol(i.col, i.world);
    return e.spans.some(e => e.row === i.row && e.colFrom <= s && e.colTo >= s);
  }
  normalizeBounds(t, o, l) {
    if (t[0] = o[0], t[1] = o[1], t[2] = o[2], t[3] = o[3], this._wrap) {
      const o = e(this.tileInfo.spatialReference),
        s = -l * (o.valid[1] - o.valid[0]);
      t[0] += s, t[2] += s;
    }
    return t;
  }
  getSmallestInfoForScale(e) {
    const t = this.scales;
    if (this._infoByScale[e]) return this._infoByScale[e];
    if (e > t[0]) return this._infoByScale[t[0]];
    for (let o = 1; o < t.length - 1; o++) if (e > t[o] + a) return this._infoByScale[t[o - 1]];
    return this._infoByScale[t[t.length - 1]];
  }
  getClosestInfoForScale(e) {
    const t = this.scales;
    return this._infoByScale[e] || (e = t.reduce((t, o) => Math.abs(o - e) < Math.abs(t - e) ? o : t, t[0])), this._infoByScale[e];
  }
  scaleToLevel(e) {
    const t = this.scales;
    if (this._infoByScale[e]) return this._infoByScale[e].level;
    for (let o = t.length - 1; o >= 0; o--) if (e < t[o]) {
      if (o === t.length - 1) return this._infoByScale[t[t.length - 1]].level;
      return this._infoByScale[t[o]].level + (t[o] - e) / (t[o] - t[o + 1]);
    }
    return this._infoByScale[t[0]].level;
  }
  scaleToZoom(e) {
    return this.tileInfo.scaleToZoom(e);
  }
  zoomToScale(e) {
    return this.tileInfo.zoomToScale(e);
  }
  _getTileIdAtLOD(e, t, o) {
    const l = this._infoByLevel[o.level];
    return e.set(o), t.resolution < l.resolution ? null : (t.resolution === l.resolution || (e.level = t.level, e.col = Math.floor(o.col * l.resolution / t.resolution + .01), e.row = Math.floor(o.row * l.resolution / t.resolution + .01)), e);
  }
}
export { h as default };